import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  selectCartItems,
  selectCartTotal,
} from '../../redux/cart/cart.selectors';

import './checkout.styles.scss';

import CheckoutItem from '../../components/checkout-item/checkout-item.component';
import StripeCheckoutButton from '../../components/stripe-button/stripe-button.component';

const CheckoutPage = ({ cartItems, total, ...props }) => {
  return (
    <div className="checkout-page">
      <div className="checkout-header">
        <div className="header-block product">
          <span>Product</span>
        </div>
        <div className="header-block description">
          <span>Description</span>
        </div>
        <div className="header-block quantity">
          <span>Quantity</span>
        </div>
        <div className="header-block price">
          <span>Price</span>
        </div>
        <div className="header-block remove">
          <span>Remove</span>
        </div>
      </div>
      {cartItems.map(item => <CheckoutItem key={item.id} item={item} />)}
      <div className="total">TOTAL: ${total}</div>
      <div className="test-warning">
        *Please use the following test credit card for payments* <br/>
        4242 4242 4242 4242 - Exp: 01/20 - CVV: 123
      </div>
      <StripeCheckoutButton price={total} />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
  total: selectCartTotal,
});

export default connect(mapStateToProps)(CheckoutPage);
